<template>
  <div>
    <CModal :show.sync="$store.state.showModal" :no-close-on-backdrop="true" :centered="true" title="Modal title 2"
      size="lg" color="dark">
      <template v-if="form" #header>
        <h6 class="modal-title font-weight-bold">
          تعديل القسم: {{ form.name }}
        </h6>
        <CButtonClose @click="closeModal" class="text-white" />
      </template>
      <CRow v-if="form">
        <CCol col="12">
          <CCard>
            <CCardBody>
              <CForm>
                <CRow class="mt-3">
                  <CCol sm="12" md="9">
                    <CInput invalid description="" label="الاسم" horizontal autocomplete="name" v-model.trim="form.name"
                      invalid-feedback="يوجد خطأ في هذا الحقل" :is-valid="!$v.form.name.$anyError" />
                  </CCol>
                </CRow>

                <CRow class="mt-5">
                  <CCol sm="12" md="9">
                    <div role="group" class="form-group form-row">
                      <label class="col-form-label col-sm-3">الاقسام</label>
                      <CCol sm="9">
                        <v-select :multiple="true" :options="categories" v-model="form.categories"
                          :reduce="(category) => category.id" dir="rtl" label="name" placeholder="اختار الاقسام">
                          <template> عذراً هذه الاقسام غير موجود </template>
                          <template v-if="$v.form.categories.$anyError" #footer>
                            <div style="opacity: 0.8" class="text-danger mt-2">
                              يجب عليك اختيار الاقسام
                            </div>
                          </template>
                        </v-select>
                      </CCol>
                    </div>
                  </CCol>
                </CRow>

                <CRow class="mt-5">
                  <CCol sm="12" md="9">
                    <div role="group" class="form-group form-row">
                      <label class="col-form-label col-sm-3"> الصورة </label>
                      <CCol sm="12" md="9">
                        <VueFileAgent ref="vueFileAgent" :theme="'grid'" :multiple="false" :deletable="true" :meta="true"
                          :accept="'image/*'" :maxSize="'3MB'" :maxFiles="1" :helpText="'اختار صورة او اسحب الى هنا'"
                          :errorText="{
                            type: 'نوع الملف غير صالح . الملفات من نوع صور فقط التي يتم قبولها',
                            size: 'حجم الملف اكبر من ٣ ميجا',
                          }" @select="filesSelected($event)" @beforedelete="onBeforeDelete($event)"
                          @delete="fileDeleted($event)"></VueFileAgent>
                      </CCol>
                      <div class="mt-3">
                        <CImg :src="form.image_url" fluid width="150px" height="150px" />
                      </div>
                      <div class="mt-3 text-danger" v-if="$v.form.image.$anyError">
                        <span>يوجد خطأ في الصورة</span>
                      </div>
                    </div>
                  </CCol>
                </CRow>

                <CRow class="mt-1">
                  <CCol sm="12" md="9">
                    <div role="group" class="form-group form-row">
                      <label class="col-form-label col-sm-3"> حالة القسم </label>
                      <CCol sm="9">
                        <div role="group" class="form-check form-check-inline">
                          <label>
                            <input type="radio" name="category_id" class="form-check-input" value="1"
                              v-model="form.status" />
                            نشط
                          </label>
                        </div>

                        <div role="group" class="form-check form-check-inline">
                          <label>
                            <input type="radio" name="category_id" class="form-check-input" value="0"
                              v-model="form.status" />
                            متوقف
                          </label>
                        </div>
                      </CCol>
                    </div>
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CRow v-if="formErrors">
        <CCol col="12">
          <CAlert color="danger">
            <CListGroup flush>
              <CListGroupItem v-for="(err, index) in formErrors" :key="index">
                {{ err[0] }}
              </CListGroupItem>
            </CListGroup>
          </CAlert>
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="closeModal" color="danger">الغاء</CButton>
        <CButton :disabled="loading" @click="update" type="button" color="success">
          <CSpinner v-if="loading" color="white" size="sm" />
          <span v-else>
            <CIcon name="cil-save" /> حفظ
          </span>
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { required, maxLength, minLength } from "vuelidate/lib/validators";
export default {
  name: "Edit",
  props: ["form", "categories"],
  data() {
    return {
      loading: false,
      formErrors: null,
      fileRecords: [],
      fileRecordsForUpload: [],
    };
  },
  validations: {
    form: {
      name: { required, min: minLength(2), max: maxLength(200) },
      status: { required },
      image: {},
      categories: { required },
    },
  },
  methods: {
    closeModal() {
      this.$store.commit("set", ["showModal", false]);
    },
    update() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return true;
      }
      this.loading = true;

      this.$http
        .put(`/subcategories/${this.form.id}`, {
          name: this.form.name,
          status: this.form.status,
          image: this.form.image,
          categories: this.prepareCategories(this.form.categories),
        })
        .then((res) => {
          // handle success
          if (res.data.status == 200) {
            this.$emit("updateDone");
            this.$store.commit("set", ["showModal", false]);
          }
          this.loading = false;
        })
        .catch((error) => {
          // handle error
          this.loading = false;
          if (error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      const reader = new FileReader();
      reader.onloadend = () => {
        this.form.image = reader.result;
      };
      reader.readAsDataURL(validFileRecords[0].file);
    },
    onBeforeDelete: function (fileRecord) {
      this.$refs.vueFileAgent.deleteFileRecord(fileRecord);
    },
    deleteUploadedFile: function (fileRecord) {
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
      );
    },
    fileDeleted: function (fileRecord) {
      this.form.image = null;
    },

    prepareCategories: function(cats) {
      if (cats.length) {

        if (cats.every(element => typeof element === 'number')) {
          return cats;
        }

        return cats.map(c => c.id);
      }

      return [];
    }
  },
};
</script>
